import React, { Fragment, useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { get, noop } from 'lodash';
import { useTimeout } from 'site-modules/shared/hooks/use-timeout';
import Tooltip from 'reactstrap/lib/Tooltip'; // eslint-disable-line no-restricted-imports
import Button from 'reactstrap/lib/Button';
import { randomInt } from 'client/utils/seed-randomizers';

import './tooltip-text-item.scss';

const CLICK_EVENTS = ['click', 'touchend'];
const LEAVE_EVENTS = ['mouseleave'];

// Simplified version of TooltipItem for text instead of icon
export function TooltipTextItem({
  id,
  buttonClassName,
  children,
  item: { placement, text },
  trackingId,
  trackingValue,
  ariaLabel,
  tooltipClassName,
  onTooltipOpen,
}) {
  const [tooltipOpen, setTooltipOpen] = useState(false);
  const [focusinEvent, setFocusinEvent] = useState(false);
  const idPrefix = useRef(randomInt());
  const tooltipElement = useRef();
  const [setLastTooltipOpenDelayTimeout, clearLastTooltipOpenDelayTimeout] = useTimeout();
  const tooltipId = `tooltip${id}${idPrefix.current}`;

  useEffect(() => {
    if (tooltipOpen && !focusinEvent) {
      onTooltipOpen({ id });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [tooltipOpen]);

  const closeHandler = useCallback(e => {
    if (CLICK_EVENTS.includes(e.type) && get(tooltipElement, 'current') === e.target) {
      e.stopPropagation();
    }

    setTooltipOpen(false);
    CLICK_EVENTS.forEach(event => document.body.removeEventListener(event, closeHandler));
    LEAVE_EVENTS.forEach(event => tooltipElement.current.removeEventListener(event, closeHandler));
  }, []);

  const registerCloseEvent = useCallback(() => {
    CLICK_EVENTS.forEach(event => document.body.addEventListener(event, closeHandler));
    LEAVE_EVENTS.forEach(event => tooltipElement.current.addEventListener(event, closeHandler));
  }, [closeHandler]);

  const toggle = useCallback(
    e => {
      if (e.persist) {
        e.persist();
      }

      // TODO: Once we upgrade our Reactstrap to a version later than 8.0.0 with a fix for the issue,
      //  remove below delay based on Reactstrap community-suggested workaround to conflicting mobile event handlers.
      //  See: https://github.com/reactstrap/reactstrap/issues/1425 | EVAL-2117
      clearLastTooltipOpenDelayTimeout();

      setLastTooltipOpenDelayTimeout(() => {
        const isTooltipOpen = !tooltipOpen;
        setTooltipOpen(isTooltipOpen);
        setFocusinEvent(e.type === 'focusin');
        if (isTooltipOpen) {
          registerCloseEvent();
        }
      }, 100); // Must be >10ms to work in Browserstack iPhone 11 Pro Safari (iOS 13)
    },
    [clearLastTooltipOpenDelayTimeout, registerCloseEvent, setLastTooltipOpenDelayTimeout, tooltipOpen]
  );

  return (
    <Fragment>
      <Button
        color="link"
        className={classnames('tooltip-text-btn text-transform-none', buttonClassName)}
        id={tooltipId}
        {...(tooltipOpen ? { 'aria-describedby': `${tooltipId}-sr-content` } : {})}
        onClick={toggle}
        innerRef={tooltipElement}
        data-tracking-id={trackingId}
        data-tracking-value={trackingValue}
        aria-label={ariaLabel}
      >
        {children}
        {/* Added so that a screen reader can read tooltip text. */}
        {tooltipOpen && (
          <span id={`${tooltipId}-sr-content`} role="tooltip" className="sr-only">
            {text}
          </span>
        )}
      </Button>
      <Tooltip
        className={classnames('tooltip--white', tooltipClassName)}
        placement={placement}
        isOpen={tooltipOpen}
        target={tooltipId}
        id={`${tooltipId}-container`}
        modifiers={{ preventOverflow: { boundariesElement: 'viewport' } }}
        /* DO NOT REMOVE flip={false} UNTIL https://github.com/reactstrap/reactstrap/issues/1482 IS FIXED */
        flip={false} /* SEE: https://edmunds.atlassian.net/browse/PROD-394368 */
        toggle={toggle}
      >
        {text}
      </Tooltip>
    </Fragment>
  );
}

TooltipTextItem.propTypes = {
  id: PropTypes.string.isRequired,
  item: PropTypes.shape({
    text: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
    placement: PropTypes.string.isRequired,
  }).isRequired,
  children: PropTypes.oneOfType([PropTypes.node, PropTypes.string]).isRequired,
  buttonClassName: PropTypes.string,
  trackingId: PropTypes.string,
  trackingValue: PropTypes.string,
  ariaLabel: PropTypes.string,
  isMobile: PropTypes.bool,
  tooltipClassName: PropTypes.string,
  onTooltipOpen: PropTypes.func,
};

TooltipTextItem.defaultProps = {
  buttonClassName: 'p-0',
  trackingId: null,
  trackingValue: null,
  ariaLabel: null,
  isMobile: false,
  tooltipClassName: '',
  onTooltipOpen: noop,
};
